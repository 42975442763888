import React from "react"
import theme from "../../themes"
import { device } from "../../themes/device"
import { Link } from "gatsby"
import List from "../List"
import ListItem from "../ListItem"
import arrow from "../../images/ArrowDown.svg"

const Services = () => {
  const styling = {
    border: {
      default: "none",
      bottom: "2px solid black",
    },
    padding: {
      default: "24px",
      top: "16px",
      left: "16px",
    },
  }

  return (
    <List>
      <ListItem
        border={styling.border.borderBottom}
        borderBottom={styling.border.bottom}
        padding={`${styling.padding.top} ${styling.padding.default}`}
      >
        <Link
          style={{ textDecoration: "none", color: "black" }}
          to="/webentwicklung"
        >
          Webentwicklung
        </Link>
      </ListItem>
    </List>
  )
}

export default Services
