import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import H1 from "../components/H1"
import Hero2 from "../components/Hero2"
import Container from "../components/Container"
import hero_agency from "../images/hero_agency.svg"
import ImageBlock from "../components/ImageBlock"
import P from "../components/P"
import H2 from "../components/H2"
import Section from "../components/Section"
import Services from "../components/Services"
import LinkButton from "../components/LinkButton"
import hero_ready from "../images/hero_ready.svg"
import Grid from "../components/Grid"

const meta = {
  title: "Startseite",
  description: `Möchten Sie im Internet durchstarten? Wir sind Ihre kreative Webagentur aus Luzern. Wir entwickeln moderne Webseiten und Apps für Unternehmen.`,
  keywords: "webagentur, webseite, apps, webseite entwickeln, eigene webseite",
}

const IndexPage = () => (
  <Layout>
    <SEO
      title={meta.title}
      description={meta.description}
      keywords={meta.keywords}
    />
    <Hero2
      height={"100vh"}
      marginBottom={"0"}
      marginBottomSm={"0"}
      marginBottomMd={"0"}
    >
      <Container>
        <H1>Startklar für's Web? </H1>
        <P>
          Mit Konzept, Fokus und Leidenschaft bringen wir Ihr Unternehmen ins
          Internet.
        </P>
        <LinkButton to="/#agency">Loslegen</LinkButton>
      </Container>
      <ImageBlock
        alt="Startklar fürs Internet?"
        src={hero_ready}
        height={"300px"}
        heightSm={"auto"}
        heightMd={"auto"}
        width={"auto"}
        widthSm={"1920px"}
        widthMd={"1920px"}
        position={{
          position: "absolute",
          bottom: "5%",
          zIndex: "-1",
          left: "-420px",
        }}
        positionSm={{
          position: "absolute",
          bottom: "20%",
          left: "-480px",
          zIndex: "-1",
        }}
        positionMd={{
          position: "absolute",
          bottom: "-10px",
          left: "-20%",
          zIndex: "-1",
        }}
        positionLg={{
          position: "absolute",
          bottom: "-10px",
          left: "-0%",
          zIndex: "-1",
        }}
      />
    </Hero2>
    <Section
      marginBottom="0"
      marginBottomSm="0"
      marginBottomMd="0"
      paddingMd={"120px 0"}
      paddingLg={"440px 0"}
      backgroundMd={hero_agency}
      role="img"
      ariaLabel="Webagentur"
    >
      <Container id="agency">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <H2>Die kreative Webagentur aus Luzern.</H2>
            <P>
              Wir beraten und unterstützen Sie bei Ihren Herausforderungen rund
              ums Thema Web. Vom Konzept bis zum entgültigen Produkt.
            </P>

            <H2>Unsere tägliche Mission</H2>
            <Services />
          </Grid>

          <Grid item xs={12} md={6}></Grid>
        </Grid>
      </Container>
    </Section>
  </Layout>
)

export default IndexPage
